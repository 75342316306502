<template lang="pug">
church-profile(v-if="isChurchPastor" :info="church[0]")
individual-profile(v-else-if="!profileIsLoading && profile.length !== 0" :info="profile[0]")
</template>

<script>
import { useFind } from '@feathersjs/vuex';
import { computed, defineComponent, getCurrentInstance } from 'vue';
import { mapState, mapActions } from 'vuex';

import ChurchProfile from '../components/profile.components/ChurchProfile.vue';
import IndividualProfile from '../components/profile.components/IndividualProfile.vue';

export default defineComponent({
    watch: {
        profileIsLoading(isLoading) {
            if(this.profile.length === 0 && !this.isChurchPastor && !isLoading) {
                this.createUserProfile({
                    user_id: this.user.id
                });
            }
        }
    },
    setup() {
        const vm = getCurrentInstance();
        const { Church, UserProfile }  = vm.appContext.provides.$FeathersVuex.api;
        const user = vm.appContext.provides.store.state.auth.user;

        const churchData = useFind({ model: Church, params: computed(() => {
            return { query: { owner_id: user.id } }
        }), immediate: true });

        const userProfileData = useFind({ model: UserProfile, params: computed(() => {
            return { query: { user_id: user.id } }
        }), immediate: true });

        return {
            church: churchData.items,
            profile: userProfileData.items,
            profileIsLoading: userProfileData.isPending
        };
    },
    computed: {
        isChurchPastor() {
            return this.church.length !== 0;
        },
        ...mapState('auth', ['user'])
    },
    methods: {
        ...mapActions('user-profile', { createUserProfile: 'create' })
    },
    components: {
        ChurchProfile,
        IndividualProfile
    }
})
</script>
