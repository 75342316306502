<template lang="pug">
input(type="file" ref="file-selector" hidden @change="fileSelected")
button.btn.btn-upload(type="button" @click="selectImage" :disabled="loading") Upload Image
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        modelValue: String
    },
    emits: ['update:modelValue', 'loading'],
    data() {
        return {
            loading: false
        };
    },
    methods: {
        selectImage() {
            this.$refs['file-selector'].click();
        },
        fileSelected(value) {
            const reader = new FileReader()
            reader.readAsDataURL(value.target.files[0]);
            reader.onload = () => {
                const connection = process.env.NODE_ENV === 'development'
                    ? 'http://localhost:3030/'
                    : '/'
                
                // console.log(reader.result);
                this.$emit('update:modelValue', reader.result);

                this.loading = true;
                this.$emit('loading', true);
                fetch(`${connection}api/upload`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": window.localStorage.getItem('feathers-jwt')
                    },
                    body: JSON.stringify({
                        uri: reader.result
                    })
                }).then(response => response.json()).then(result => {
                    if(result.code) throw new Error(result.message);
                    this.$emit('update:modelValue', `https://uploads.quivershare.org/uploads/${result.id}`);
                }).catch(err => {
                    this.$toast(err.message === 'request entity too large' ? 'File is too large and max file size is 5mb.' : err.message, {
                        duration: 3000,
                        styles:  {
                            fontSize: '11pt',
                            backgroundColor: '#ce4f4f'
                        },
                        slot: '<i class="fa fa-exclamation"></i>'
                    });
                }).finally(() => {
                    this.loading = false;
                    this.$emit('loading', false);
                });

            };
        }
    }
})
</script>
