<template>
<div ref="modal" class="modal fade" id="modal_change_pass" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_changepassLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content modal-change-pass-content">
            <div class="modal-body">
                <div class="head-mod-container">
                    <h3 class="head-modal-title">
                    Change Password
                    </h3>
                    <p class="des-modal">
                    Please filled all forms to proceed with changing password.
                    </p>
                </div>
                <form class="change-pass-form">
                    <div class="mb-3">
                        <label for="old-pass" class="col-form-label">Old Password</label>
                        <input v-model="form.old_password" type="password" placeholder="Enter your old password" id="old-pass" :class="formControl(v$.form.old_password)" :disabled="loading">
                        <div v-if="v$.form.old_password.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.old_password.$errors[0].$validator, 'Old password') }}</span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="new-pass" class="col-form-label">New Password</label>
                        <input v-model="form.password" type="password" placeholder="Enter your new password" id="new-pass" :class="formControl(v$.form.password)" :disabled="loading">
                        <div v-if="v$.form.password.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.password.$errors[0].$validator, 'New password') }}</span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="confirm-pass" class="col-form-label">Confirm Password</label>
                        <input v-model="form.confirm_password" type="password" placeholder="Re-enter your password" id="confirm-pass" :class="formControl(v$.form.confirm_password)" :disabled="loading">
                        <div v-if="v$.form.confirm_password.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.confirm_password.$errors[0].$validator, 'Confirm password') }}</span>
                        </div>
                    </div>
                </form>
                <div class="d-grid gap-2">
                    <button type="button" class="btn btn-submit-change" @click="onSubmit">Submit</button>
                    <button type="button" class="btn btn-cancel-modal" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent, getCurrentInstance, ref } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
    data() {
        return {
            loading: false,
            form: {
                old_password: null,
                password: null,
                confirm_password: null
            }
        };
    },
    setup() {
        const vm = getCurrentInstance();
        const userCache = vm.appContext.provides.store.state.auth.user;
        const user = ref(userCache);
        return {
            v$: useVuelidate(),
            user
        }
    },
    validations () {
        return {
            form: {
                old_password: { required },
                password: { required },
                confirm_password: { required, confirmPassword: this.confirmPassword }
            }
        };
    },
    methods: {
        async onSubmit() {
            const isFormCorrect = await this.v$.$validate();
            if(!isFormCorrect) return;
            try {
                this.loading = true;
                await this.updateUser([ this.user.id, this.form ]);
                this.$toast('Successfully Saved!', {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#6C782A'
                    },
                    slot: '<i class="fa fa-check"></i>'
                });
                this.v$.form.$reset();
                this.form.old_password = '';
                this.form.password = '';
                this.form.confirm_password = '';
                window['$'](this.$refs.modal).modal('hide');
            } catch(err) {
                this.$toast(err.message, {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#ce4f4f'
                    },
                    slot: '<i class="fa fa-exclamation"></i>'
                });
            } finally {
                this.loading = false;
            }
        },
        confirmPassword(value) {
            return value === this.form.password;
        },
        formControl(form) {
            return {
                'form-control': true,
                'is-invalid': form.$dirty && form.$invalid,
                'is-valid': form.$dirty && !form.$invalid
            };
        },
        errorMessage(type, name) {
            switch(type) {
                case 'required': return `${name} is required`;
                case 'confirmPassword': return `Invalid confirmation password`;
            }
        },
        ...mapActions('users', { updateUser: 'patch' }),
    }
})
</script>
