<template>
    <section class="profile-section">
        <div class="container my-container">
            <div class="profile-content">
                <h4>My Profile</h4>
                <div class="row no-gutters">
                    <div class="col-md-2">
                        <h5>Profile Picture</h5>
                        <div class="overlay">
                            <img ref="profile-avatar" :src="userForm.avatar || require('../../assets/img/feeds/user_img_placeholder.png')" class="img-fluid" alt="">
                            <div v-if="avatarLoading" class="overlay-loading">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto; vertical-align: middle" width="70px" height="70px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <path d="M17 50A33 33 0 0 0 83 50A33 35.2 0 0 1 17 50" fill="#ffffff" stroke="none">
                                        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51.1;360 50 51.1"></animateTransform>
                                    </path>
                                </svg>
                            </div>
                        </div>
                        <div class="d-grid gap-3 btn-grp">
                            <SelectAvatar v-model="userForm.avatar" @loading="avatarLoading=$event" />
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="profile-info-container">
                            <h5>Personal Information</h5>
                            <div class="row no-gutters">
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label for="user_fname" class="form-label">First Name</label>
                                        <!-- <input type="text" class="form-control" id="user_fname" value="John" readonly> -->
                                        <input v-model="userForm.fname" type="text" id="first_name" :class="formControl(v$.userForm.fname)" :disabled="loading">
                                        <div v-if="v$.userForm.fname.$error" class="invalid-feedback">
                                            <span>{{ errorMessage(v$.userForm.fname.$errors[0].$validator, 'First name') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label for="user_lname" class="form-label">Last Name</label>
                                        <input v-model="userForm.lname" type="text" id="user_lname" :class="formControl(v$.userForm.lname)" :disabled="loading">
                                        <div v-if="v$.userForm.lname.$error" class="invalid-feedback">
                                            <span>{{ errorMessage(v$.userForm.lname.$errors[0].$validator, 'Last name') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label for="user_cmem" class="form-label">Church Membership</label>
                                        <input type="text" class="form-control" id="user_cmem" :value="church && church.name || '---'" disabled>
                                    </div>
                                </div>
                            </div>
                            <h5>Shipping Address</h5>
                            <div class="row no-gutters">
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label for="user_fname" class="form-label">Address Line 1</label>
                                        <input v-model="form.address_line_1" type="text" id="user_addr1" :class="formControl(v$.form.address_line_1)" :disabled="loading">
                                        <div v-if="v$.form.address_line_1.$error" class="invalid-feedback">
                                            <span>{{ errorMessage(v$.form.address_line_1.$errors[0].$validator, 'Address line 1') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label for="user_addr2" class="form-label">Address Line 2</label>
                                        <input v-model="form.address_line_2" type="text" id="user_addr2" :class="formControl(v$.form.address_line_2)" :disabled="loading">
                                        <div v-if="v$.form.address_line_2.$error" class="invalid-feedback">
                                            <span>{{ errorMessage(v$.form.address_line_2.$errors[0].$validator, 'Address line 2') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="mb-3">
                                        <label for="user_state" class="form-label">State</label>
                                        <!-- <input type="text" class="form-control" id="user_state"  value='Manila' readonly> -->
                                        <select ref="state-select" v-model="form.state" id="user_state" :class="formControl(v$.form.state)" :disabled="loading">
                                            <option selected value="">Select State</option>
                                            <option v-for="(item, index) in states" :value="item.state" :key="index">{{ item.country }}</option>
                                        </select>
                                        <div v-if="v$.form.state.$error" class="invalid-feedback">
                                            <span>{{ errorMessage(v$.form.state.$errors[0].$validator, 'State') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="mb-3">
                                        <label for="user_city" class="form-label">City</label>
                                        <!-- <input type="text" class="form-control" id="user_city"  value='Mandaluyong' readonly> -->
                                        <select ref="city-select" v-model="form.city" id="user_city" :class="formControl(v$.form.city)" :disabled="loading">
                                            <option selected value="">Select City</option>
                                            <option v-for="(item, index) in cities" :value="item" :key="index">{{ item }}</option>
                                        </select>
                                        <div v-if="v$.form.city.$error" class="invalid-feedback">
                                            <span>{{ errorMessage(v$.form.city.$errors[0].$validator, 'City') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="mb-3">
                                        <label for="user_zpcode" class="form-label">Zipcode</label>
                                        <!-- <input type="text" class="form-control" id="user_zpcode" value='02979' readonly> -->
                                        <select ref="zipcode-select" v-model="form.zipcode" id="user_zpcode" :class="formControl(v$.form.zipcode)" :disabled="loading">
                                            <option selected value="">Select Zipcode</option>
                                            <option v-for="(item, index) in zips" :value="item.zip" :key="index">{{ item.zip }}</option>
                                        </select>
                                        <div v-if="v$.form.zipcode.$error" class="invalid-feedback">
                                            <span>{{ errorMessage(v$.form.zipcode.$errors[0].$validator, 'Zipcode') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5>Childrens</h5><a href="" class="btn-link-add" data-bs-toggle="modal" data-bs-target="#modal_add_children"><i class="fas fa-plus-circle"></i> Add Another Grade</a>
                            <div class="row no-gutters">
                                <div v-if="childrens.length === 0" class="mt-5 mb-5 text-center">
                                    <b>No Childrens!</b>
                                </div>

                                <template v-for="(item, index) in childrens" :key="item.id">
                                    <div class="col-sm-5">
                                        <div class="mb-3">
                                            <label v-if="index === 0" :for="`user_child_grade_${item.id}`" class="form-label">Homeschooling Chrildren</label>
                                            <input type="text" class="form-control" :id="`user_child_grade_${item.id}`" :value="item.grade" readonly>
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="mb-3">
                                            <label v-if="index === 0" :for="`user_child_count_${item.id}`" class="form-label">No. of Children</label>
                                            <input type="text" class="form-control" :id="`user_child_count_${item.id}`" :value="item.count" readonly>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="d-grid gap-3 btn-grp">
                                            <label v-if="index === 0" for="user_lname" class="form-label"></label>
                                            <button class="btn btn-delete" type="button" @click="item.remove()">Delete</button>
                                        </div>
                                    </div>
                                </template>

                            </div>
                            <h5>Profile Page Remarks</h5>
                            <div class="row no-gutters">
                                <div class="col-sm-12">
                                    <div class="mb-4">
                                        <textarea v-model="form.remarks" id="remarksTextarea" rows="4" :class="formControl(v$.form.remarks)" :disabled="loading"></textarea>
                                        <div v-if="v$.form.remarks.$error" class="invalid-feedback">
                                            <span>{{ errorMessage(v$.form.remarks.$errors[0].$validator, 'Remarks') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button class="btn btn-edit-prof me-md-2" type="button" @click="updateProfile" :disabled="loading || avatarLoading">Edit Profile</button>
                            <button class="btn btn-change-pass" type="button" data-bs-toggle="modal" data-bs-target="#modal_change_pass">Change Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalChangePassword />
        <ModalAddChildren />
    </section>
</template>

<script>
import { useFind, useGet } from '@feathersjs/vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { computed, defineComponent, getCurrentInstance, ref } from 'vue';
import { mapActions } from 'vuex';

import ModalChangePassword from '../modals.component/modal-change-password.vue';
import ModalAddChildren from '../modals.component/modal-add-children.vue';
import SelectAvatar from './SelectAvatar.vue';

export default defineComponent({
    props: {
        info: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            loading: false,
            avatarLoading: false
        };
    },
    setup(props) {
        const vm = getCurrentInstance();
        const { Country, ZipCodes, User, Church, UserChurch, UserChildrens }  = vm.appContext.provides.$FeathersVuex.api;
        const userCache = vm.appContext.provides.store.state.auth.user;

        const user = useGet({ id: userCache.id, model: User });

        const form = ref({
            address_line_1: props['info'].address_line_1,
            address_line_2: props['info'].address_line_2,
            state: props['info'].state || '',
            city: props['info'].city || '',
            zipcode: props['info'].zipcode || '',
            remarks: props['info'].remarks
        });

        const userForm = ref({
            fname: user.item.value.fname,
            lname: user.item.value.lname,
            avatar: user.item.value.avatar
        });

        const countryData = useFind({ model: Country, params: computed(() => ({})), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });
        
        const zipcode = useFind({ model: ZipCodes, params: computed(() => {
            return { query: { state: form.value.state } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false, state: form.value.state } };
        }), immediate: true });

        const zip = useFind({ model: ZipCodes, params: computed(() => {
            return { query: { state: form.value.state, city: form.value.city } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false, state: form.value.state, city: form.value.city } };
        }), immediate: true });

        const userChurch = useFind({ model: UserChurch, params: computed(() => {
            return { query: { user_id: userCache.id } };
        }) });
        
        const userChildrens = useFind({ model: UserChildrens, params: computed(() => {
            return { query: { user_id: userCache.id } };
        }), fetchParams: computed(() => {
            return { query: { user_id: userCache.id, $paginate: false } };
        }) });

        const church = useGet({ model: Church, id: computed(() => {
            if(!userChurch.isPending.value && userChurch.items.value.length !== 0) {
                return userChurch.items.value[0].church_id;
            } else {
                return null;
            }
        }), immediate: true });

        return {
            states: countryData.items,
            zipcodes: zipcode.items,
            zips: zip.items,
            user: user.item,
            userForm,
            form,
            v$: useVuelidate(),
            church: church.item,
            churchPending: church.isPending,
            childrens: userChildrens.items
        };
    },
    validations () {
        return {
            form: {
                address_line_1: { required },
                address_line_2: { required },
                state: { required },
                city: { required },
                zipcode: { required },
                remarks: { required }
            },
            userForm: {
                fname: { required },
                lname: { required }
            }
        };
    },
    computed: {
        cities() {
            return this.zipcodes.reduce((ret, item) => {
                if(ret.indexOf(item.city) === -1) {
                    ret.push(item.city);
                }
                return ret;
            }, []);
        }
    },
    mounted() {
        window['$'](this.$refs['state-select']).select2({
            width:'resolve'
        }).on('select2:select', (e) => this.form.state = e.target.value);
        window['$'](this.$refs['city-select']).select2({
            width:'resolve'
        }).on('select2:select', (e) => this.form.city = e.target.value);
        window['$'](this.$refs['zipcode-select']).select2({
            width:'resolve'
        }).on('select2:select', (e) => this.form.zipcode = e.target.value);
    },
    methods: {
        async updateProfile() {
            await this.v$.$validate();
            if(this.v$.form.$error || this.v$.userForm.$error) return;
            try {
                this.loading = true;
                await this.updateUser([ this.user.id, this.userForm ]);
                await this.updateProfileInformation([ this.info.id, this.form ]);
                this.$toast('Successfully Saved!', {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#6C782A'
                    },
                    slot: '<i class="fa fa-check"></i>'
                });
            } catch(err) {
                this.$toast(err.message, {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#ce4f4f'
                    },
                    slot: '<i class="fa fa-exclamation"></i>'
                });
            } finally {
                this.loading = false;
            }
        },
        formControl(form) {
            return {
                'form-control': true,
                'is-invalid': form.$dirty && form.$invalid,
                'is-valid': form.$dirty && !form.$invalid
            };
        },
        errorMessage(type, name) {
            switch(type) {
                case 'required': return `${name} is required`;
            }
        },
        ...mapActions('users', { updateUser: 'patch' }),
        ...mapActions('user-profile', { updateProfileInformation: 'patch' })
    },
    components: {
        ModalChangePassword,
        SelectAvatar,
        ModalAddChildren
    }
})
</script>

<style scoped>
.overlay {
    position: relative;
}
.overlay .overlay-loading {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #0000006b;
    border-radius: 20px;
    vertical-align: middle;
}

</style>