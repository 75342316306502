<template>
    <div ref="modal" class="modal fade" id="modal_select_admin" tabindex="-1" aria-labelledby="modal_modal_select_adminLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-select-admin-content">
                <div class="modal-body">
                    <div class="head-mod-container">
                        <p class="des-modal">
                            Select Member as Administrator
                        </p>
                    </div>
                    <div class="user_list_container">
                        <ul class="list-group list-group-flush">
                            <li v-for="(item, index) in churchMemberLists" class="list-group-item" :key="index">
                                <input v-model="formRadio[item.user_id].value" class="form-check-input me-1" type="checkbox" :value="item.user_id" aria-label="...">
                                {{ item.user.fname }} {{ item.user.lname }}
                            </li>
                        </ul>
                    </div>
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-submit-add" @click="submit">Add</button>
                        <button type="button" class="btn btn-cancel-modal" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useFind } from '@feathersjs/vuex';
import { computed, defineComponent, getCurrentInstance } from 'vue'
import { mapActions, mapState } from 'vuex';

export default defineComponent({
    props: {
        churchId: Number
    },
    data() {
        return {
            loading: false,
            form: {
                activeUser: []
            }
        };  
    },
    setup(props) {
        const vm = getCurrentInstance();
        const { ChurchAdmin, UserChurch }  = vm.appContext.provides.$FeathersVuex.api;
        
        const churchAdminLists = useFind({ model: ChurchAdmin, params: computed(() => {
            return { query: { church_id: props.churchId } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        const churchMemberLists = useFind({ model: UserChurch, params: computed(() => {
            return { query: { church_id: props.churchId, status: 'Confirmed' } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        return {
            churchAdminLists: churchAdminLists.items,
            churchMemberLists: churchMemberLists.items
        };
    },
    computed: {
        formRadio() {
            return this.generateRadioFormReactive();
        },
        ...mapState('auth', ['user'])
    },
    methods: {
        async submit() {
            const userIdLists = this.churchAdminLists.map(i => i.user_id);
            const adminLists = Object.keys(this.form.activeUser).filter(i => this.form.activeUser[i]).map(i => parseInt(i));
            let toRemove = userIdLists.filter(x => !adminLists.includes(x));
            let toAdd = adminLists.filter(x => !userIdLists.includes(x));
            try {
                this.loading = true;
                await this.findAdminUser({
                    query: { user_id: { $in: toRemove }, $paginate: false }
                }).then(async result => {
                    for(let item of result) {
                        await item.remove();
                    }
                });
                for(let user_id of toAdd) {
                    await this.addAdmin({
                        church_owner_id: this.user.id,
                        church_id: this.churchId,
                        user_id
                    });
                }
                window['$'](this.$refs.modal).modal('hide');
            } catch(err) {
                this.$toast(err.message, {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#ce4f4f'
                    },
                    slot: '<i class="fa fa-exclamation"></i>'
                });
            } finally {
                this.loading = false;
            }
        },
        generateRadioFormReactive() {
            const userIdLists = this.churchAdminLists.map(i => i.user_id);
            return this.churchMemberLists.reduce((obj, item) => {
                if(!obj[item.user_id]) {
                    let self = this;
                    let value = userIdLists.indexOf(item.user_id) !== -1;
                    this.form.activeUser[item.user_id] = value;
                    obj[item.user_id] = {
                        get value() {
                            return self.form.activeUser[item.user_id];
                        },
                        set value(v) {
                            self.form.activeUser[item.user_id] = v;
                        }
                    };
                }
                return obj;
            }, {});
        },
        ...mapActions('church-admin', { addAdmin: 'create', removeAdmin: 'remove', findAdminUser: 'find' })
    }
})
</script>
