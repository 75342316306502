<template>
<div ref="modal" class="modal fade" id="modal_add_children" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_changepassLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content modal-change-pass-content">
            <div class="modal-body">
                <div class="head-mod-container">
                    <h3 class="head-modal-title">
                        Add Another Grade
                    </h3>
                    <!-- <p class="des-modal">
                        Please filled all forms to proceed with changing password.
                    </p> -->
                </div>
                <form class="change-pass-form">
                    <div class="mb-3">
                        <label for="old-pass" class="col-form-label">Grade</label>

                        <select v-model="form.grade_id" aria-label="Default select example" :class="formControlSelect(v$.form.grade_id)" :disabled="loading">
                            <option selected :value="null">Select Grade</option>
                            <option v-for="(item, index) in childrenSchoolGrade" :key="index" :value="item.id">{{ item.name }}</option>
                        </select>

                        <!-- <input v-model="form.grade_id" type="text" placeholder="Enter Grade" id="grade" :class="formControl(v$.form.grade_id)" :disabled="loading"> -->
                        <div v-if="v$.form.grade_id.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.grade_id.$errors[0].$validator, 'Grade') }}</span>
                        </div>
                    </div>
                    <div class="mb-5">
                        <label for="new-pass" class="col-form-label">No. of Children</label>
                        <input v-model="form.count" type="number" placeholder="No. of Children" id="child-count" :class="formControl(v$.form.count)" :disabled="loading">
                        <div v-if="v$.form.count.$error" class="invalid-feedback">
                            <span>{{ errorMessage(v$.form.count.$errors[0].$validator, 'No. of Children') }}</span>
                        </div>
                    </div>
                </form>
                <div class="d-grid gap-2">
                    <button type="button" class="btn btn-submit-change" @click="onSubmit">Submit</button>
                    <button type="button" class="btn btn-cancel-modal" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { useFind } from '@feathersjs/vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { computed, defineComponent, getCurrentInstance, ref } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
    data() {
        return {
            loading: false,
            form: {
                grade_id: null,
                count: null
            }
        };
    },
    setup() {
        const vm = getCurrentInstance();
        const { ChildrenSchoolGrade }  = vm.appContext.provides.$FeathersVuex.api;
        const userCache = vm.appContext.provides.store.state.auth.user;
        const user = ref(userCache);

        const dataSchoolGrade = useFind({ model: ChildrenSchoolGrade, params: computed(() => {
            return { query: {} }
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } }
        }) });

        return {
            v$: useVuelidate(),
            user,
            childrenSchoolGrade: dataSchoolGrade.items
        }
    },
    validations () {
        return {
            form: {
                grade_id: { required },
                count: { required }
            }
        };
    },
    methods: {
        async onSubmit() {
            const isFormCorrect = await this.v$.$validate();
            if(!isFormCorrect) return;
            try {
                this.loading = true;
                await this.addUserChildren(this.form);
                this.$toast('Successfully Added!', {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#6C782A'
                    },
                    slot: '<i class="fa fa-check"></i>'
                });
                this.v$.form.$reset();
                this.form.grade_id = null;
                this.form.count = null;
                window['$'](this.$refs.modal).modal('hide');
            } catch(err) {
                this.$toast(err.message, {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#ce4f4f'
                    },
                    slot: '<i class="fa fa-exclamation"></i>'
                });
            } finally {
                this.loading = false;
            }
        },
        formControl(form) {
            return {
                'form-control': true,
                'is-invalid': form.$dirty && form.$invalid,
                'is-valid': form.$dirty && !form.$invalid
            };
        },
        formControlSelect(form) {
            return {
                'form-select': true,
                'is-invalid': form.$dirty && form.$invalid,
                'is-valid': form.$dirty && !form.$invalid
            };
        },
        errorMessage(type, name) {
            switch(type) {
                case 'required': return `${name} is required`;
            }
        },
        ...mapActions('user-childrens', { addUserChildren: 'create' }),
    }
})
</script>

<style scoped>
.form-select {
  font-family: 'Raleway', sans-serif;
  font-size: 13px;
  background-color: #F6F6F6;
  border: solid 1px #F2F2F2;
  height: 43px;
  border-radius: 25px;
  padding: 0 20px;
}
.form-select option {
  margin: 10px;
}
</style>